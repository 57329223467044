import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot
} from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { EmployeeService } from '@common/services';
import { ADMIN_APP_ROLES } from '@common/constants';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(private readonly _employeeService: EmployeeService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this._employeeService.employeeCurrentUserInfo$.pipe(
      switchMap((employeeCurrentUserInfo) =>
        of(
          ADMIN_APP_ROLES.includes(employeeCurrentUserInfo.appRole.keyCloakName)
        )
      )
    );
  }
}
