import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { IData, PageModel } from '@common/types';
import { IDirectory } from '@common/types/directory';
import { compareByField } from '@common/utils/util';

@Injectable({
  providedIn: 'root'
})
export class DirectoryService {
  constructor(private readonly http: HttpClient) {}

  createDirectory(directory: Partial<IDirectory>): Observable<void> {
    return this.http.post<void>('api/Directory', directory);
  }

  public retrieveDirectories(
    value: string,
    pageSize: number,
    page: number
  ): Observable<IData<IDirectory>> {
    const params = new HttpParams({
      fromObject: {
        ...(value ? { value } : {}),
        pageSize,
        page
      }
    });
    return this.http
      .get<IData<IDirectory>>('api/Directory/Get', {
        params
      })
      .pipe(
        map((res) => ({
          data: res.data.sort(compareByField('order')),
          pagination: new PageModel(res)
        }))
      );
  }

  updateDirectory(directory: IDirectory): Observable<void> {
    return this.http.put<void>('api/Directory', directory);
  }

  deleteDirectory(id: string): Observable<void> {
    return this.http.delete<void>('api/Directory', { params: { id } });
  }
}
