import { FuseNavigationItem } from '@fuse/components/navigation';

export const navigation: FuseNavigationItem[] = [
  {
    id: 'calendar',
    title: 'Календарь',
    type: 'basic',
    link: '/calendar'
  },
  {
    id: 'committees',
    title: 'Комитеты',
    type: 'basic',
    link: '/committees'
  },
  {
    id: 'users',
    title: 'Пользователи',
    type: 'basic',
    link: '/users',
    admin: true
  },
  {
    id: 'roles',
    title: 'Справочник ролей',
    type: 'basic',
    link: '/roles',
    admin: true
  },
  {
    id: 'documents',
    title: 'Справочник документов',
    type: 'basic',
    link: '/documents',
    admin: true
  }
];
